import { CacheType, DocSync, refreshCache } from '@/wasm/pkg'

const mixin = {
  activated () {
    this.docPermSync.active = true
  },
  data: function () {
    const docPermSync = new DocSync()
    docPermSync.cacheType = CacheType.DocTypePerm

    return {
      docPerm_: docPermSync.obs,
      docPermSync
    }
  },
  computed: {
    docPerm () { return this.docPerm_.doc ?? {} }
  },
  deactivated () {
    this.docPermSync.active = false
  },
  destroyed () {
    this.docPermSync.free()
  },
  methods: {
    refreshCache () {
      refreshCache()
    }
  },
  mounted () {
    this.docPermSync.active = true
  },
  watch: {
    docPermDocType: {
      immediate: true,
      handler (v) {
        this.docPermSync.id = v
      }
    }
  }
}

export default mixin
