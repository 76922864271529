<template>
  <div class="full-width d-flex flex-column flex-grow-1 overflow-y-hidden">
    <document-toolbar
      :delete-document="deleteFilterCheck"
      :is-deleted="isDeleted"
      :revert="refreshDocument"
      :save="saveDocument"
      :show-cancel="showSave"
      :show-delete="canDelete"
      :show-save="showSave"
      :special-actions="actions"
      :tab="tab"
      :warning-save="warningSave"
      no-history
      v-if="!auditController.dataAudit"
    >
      <template
        slot="append"
        v-if="showSave"
      >
        <v-menu
          :disabled="isConditionsValide"
          offset-y
          origin="right top"
          left
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <div
              class="d-flex align-center mt-2 mr-1"
              v-on="on"
            >
              <v-btn
                :color="isConditionsValideColor"
                icon
                :class="{'pulse-element-loop' : doAnimation }"
              >
                <v-icon>{{$icon('i.Checked')}}</v-icon>
              </v-btn>
              <span class="condition-validation-text">{{columnFormulaValidationText}}</span>
            </div>
          </template>
          <v-card>
            <div v-if="nameErrors.length">
              <v-card-title
                v-for="(e,index) in nameErrors"
                :key="'nameError' + index"
              >
                <span>{{e.message}}</span>
              </v-card-title>
            </div>
            <div v-else-if="duplicateColumnId">
              <v-card-title>{{$t('t.DuplicateOf')}}</v-card-title>
              <v-card-text>
                <column-ref
                  :id="duplicateColumnId"
                  can-edit-column
                />
              </v-card-text>
            </div>
            <div v-else>{{$t('t.ErrorFields')}}</div>
          </v-card>
        </v-menu>
      </template>
    </document-toolbar>
    <document-in-tab>
      <v-form
        ref="form"
        v-model="form"
        :disabled="!showSave"
        :readonly="readonly"
      >
        <translations
          class="translations "
          :label="$t('t.Name')"
          :translations.sync="computedName"
          :isNew="documentIsNew"
          :rules="required"
          :custom-errors="nameErrors"
          :disabled="!showSave"
        >
          <template v-slot:append-outer>
            <v-btn
              icon
              small
              @click="openInSidePanel"
              v-if="tab.isPristine && !isSidePanel"
            >
              <icon-tooltip
                :icon-name="'i.OpenInSidePanel'"
                :tooltip-text="$t('t.OpenInSidePanel')"
              />
            </v-btn>
          </template>
        </translations>
        <grid
          item-min-width="300px"
          class="align-center mt-4"
        >
          <v-autocomplete
            :label="$t('t.Documents')"
            :items="documentTypes"
            :disabled="!isNew"
            item-text="text"
            item-value="id"
            v-model="computedTargetDocTy"
            :hide-details="true"
          />
          <picker
            :dense="false"
            :allow-empty-params-type="false"
            :readonly="readonly"
            :rules="required"
            :clearable="false"
            :selected-id.sync="computedField"
            :selected-document.sync="computedSelectedField"
            document-types="fields"
            :filters="filters"
            :label="$t(`t.Fields`)"
            :attach="false"
            :hide-details="true"
            :max-visible-suggestions=10
            :max-suggestions=30
            ref="picker"
            :show-icon="true"
          />
        </grid>
        <component
          class="params mt-8"
          v-if="paramsTy && paramsTy !== 'none'"
          :is="paramsTy"
          :document.sync="computedParams"
          :target-doc-ty="computedFieldDocTy"
          :params-ty="paramsTy"
          v-on="$listeners"
          ref="component"
          @expression-is-valide="setExpressionValide"
          :audit-result="dataAuditResult"
          :readonly="readonly"
        />
      </v-form>
    </document-in-tab>
  </div>
</template>

<script>
import Search, { GlobalSearch } from '@/pages/search/controllers'

import DocPerm from '@/mixins/doc-perm'
import DocEdit from '@/mixins/document-editor'
import globalActions from '@/mixins/global-actions'
import sidePanelController from '@/sidePanelController'
import auditController from '@/auditController'

export default {
  mixins: [DocEdit, DocPerm, globalActions],
  components: {
    ColumnRef: () => import('@/components/documents-ref/column-ref'),
    Picker: () => import('@/components/picker'),
    DocumentToolbar: () => import('@/components/document-toolbar.vue'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    Translations: () => import('@/components/translations'),
    Grid: () => import('@/components/grid'),

    Account: () => import('./paramsType/account'),
    AccountBalance: () => import('./paramsType/account-balance'),
    AccountContactRef: () => import('./paramsType/account-contact-ref'),
    AssignedTo: () => import('./paramsType/assigned-to'),
    CondExpr: () => import('./paramsType/cond-expr/cond-expr'),
    CustomField: () => import('./paramsType/custom-field'),
    Division: () => import('./paramsType/division'),
    Document: () => import('./paramsType/document'),
    Graph: () => import('./paramsType/graph/graph'),
    InvoiceBalance: () => import('./paramsType/invoice-balance'),
    Iter: () => import('./paramsType/iter/iter'),
    Stats: () => import('./paramsType/stats/stats'),
    UserRef: () => import('./paramsType/user-ref'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  activated () {
    if (!this.isSidePanel) {
      this.$store.commit('setModule', {
        name: this.$t('t.Column'),
        icon: 'i.Columns'

      })
    }
  },
  deactivated () {
    if (!this.isSidePanel) {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(value, null, 12)
    }
  },
  data () {
    return {
      doAnimation: false,
      dataAuditResult: null,
      auditController: auditController,
      canSaveNameForm: false,
      canSaveComponent: false,
      duplicationInprogress: false,
      id: null,
      isConditionsValide: false,
      dataIsExpressionValide: false,
      dataTargetDocTy: 'accounts',
      documentTypes: [],
      checkColumnEmit: this.$nextTickDedup(this.checkColumn),
      http: this.$http().debounced(),
      nameErrors: [],
      panels: [0],
      duplicateColumnId: null,
      required: [
        v => (!!v && !this.lodash.isEqual(v, {})) || this.$t('t.IsRequired')
      ],
      selectedField: {}
    }
  },
  computed: {
    columnFormulaValidationText () {
      return this.$t(this.isConditionsValide ? 't.ColumnFormulaIsValid' : 't.ColumnFormulaIsInvalid')
    },
    canDelete () {
      return this.dataAuditResult === null && !this.document.usedByProtocol && this.document.canDelete
    },
    readonly () {
      return this.dataAuditResult !== null || !this.showSave
    },
    warningSave () {
      return this.document.usedByProtocol ? this.$t('t.UsedByProtocol') : null
    },
    isDeleted () {
      return this.document?.isDeleted
    },
    showSave () {
      return (this.isNew ? (this.docPerm.canCreate ?? true) : ((this.document?.canWrite ?? true) && !!this.paramsTy))
    },
    computedFieldDocTy () {
      return this.selectedField?.relatedDocTy ?? this.computedTargetDocTy
    },
    filters () {
      return { 'document-types': [[this.computedTargetDocTy], []] }
    },
    paramsTy () {
      return this.selectedField?.paramsTy
    },
    computedName: {
      get () { return this.document?.name },
      set (v) {
        this.document.name = v
        this.checkColumnEmit()
      }
    },
    computedTargetDocTy: {
      get () {
        return this.dataTargetDocTy
      },
      set (v) {
        this.dataTargetDocTy = v
        this.document.field = null
        this.selectedField = null
        this.$set(this.document, 'params', { v: {}, t: this.paramsTy })
      }
    },
    computedField: {
      get () { return this.document.field },
      set (v) {
        this.document.field = v
        this.validateForm()
      }
    },
    computedSelectedField: {
      get () {
        return this.selectedField
      },
      set (v) {
        this.selectedField = this.lodash.cloneDeep(v)
        if (this.document?.params?.t !== this.paramsTy) {
          this.$set(this.document, 'params', { v: {}, t: this.paramsTy })
        }
      }
    },
    computedParams: {
      get () {
        return this.document?.params?.v
      },
      set (v) {
        this.document.params.v = this.lodash.cloneDeep(v)
      }
    },
    isExpressionValide () {
      if (this.selectedField && this.selectedField.paramsTy !== 'cond-expr') {
        return true
      } else {
        return this.dataIsExpressionValide
      }
    },
    isConditionsValideColor () {
      return this.isConditionsValide && this.isExpressionValide ? 'success' : 'error'
    },
    cacheType () {
      return DocEdit.CacheType.ColumnDetail
    },
    docPermDocType () {
      return 'columns'
    },
    tab () {
      return this
    },
    canSave () {
      return this.form && this.canSaveComponent
    },
    form: {
      get () {
        return this.canSaveNameForm
      },
      set (v) {
        this.canSaveNameForm = v
      }
    },
    actionItem () {
      return {
        id: this.id,
        type: 'columns',
        extra: {
          isDeleted: this.isDeleted
        }
      }
    },
    actions () {
      const actions = []
      if (this.id && this.docPerm.canCreate) {
        actions.push({
          text: this.$t('t.Duplicate'),
          icon: this.$icon('i.Templates'),
          callback: this.duplicateColumn
        })
      }

      const restore = Search.getActions(this.actionItem).get('restore-column')
      if (restore?.validateRules([this.actionItem])) {
        actions.push({
          text: restore.name,
          icon: restore.icon,
          callback: () => restore.executeQuick(
            this.actionItem,
            () => {
              this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
              this.refreshCache()
            }
          )
        })
      }

      return actions.length ? actions : undefined
    },
    isNew () {
      return this.documentIsNew
    },
    isCondition () {
      return !!this.document?.params?.v?.conds
    }
  },
  inheritAttrs: false,
  methods: {
    async setFieldToCondition () {
      await this.$waitFor(() => this.isReady)
      if (this.isNew && this.params?.forceCondition) {
        const apiUrl = '/core/v6/cameleon/condition-field-id'
        const result = await this.$http().get(`${apiUrl}/${this.computedTargetDocTy}`)
        this.computedField = result?.data?.id
      }
    },
    async getAudit () {
      this.dataAuditResult = null

      if (!this.id || !this.auditController.dataAudit) {
        return
      }

      const apiUrl = '/core/v6/cameleon/columns'
      const x = {
        auditSource: this.auditController.dataAudit.source,
        view: this.auditController.dataAudit.view
      }

      const result = await this.$http().post(`${apiUrl}/${this.id}/audit`, x)
      this.dataAuditResult = result.data
    },
    async refreshDocumentHook () {
      if (this.isNew) {
        this.document = Object.assign(
          {},
          this.document,
          {
            name: this.document?.name || {},
            field: null,
            params: { v: {} }
          }
        )
      }

      this.dataTargetDocTy = this.document?.viewType ?? this.params?.targetDocTy ?? 'accounts'
      const docTypes = (await this.getDocumentTypes()) ?? []
      if (docTypes?.length) {
        this.documentTypes = docTypes.filter(p => p !== 'cultures').map(p => { return { id: p, text: this.$t(`t.${this.toPascalCase(p)}`) } })
      }
      this.validateForm()
      this.$emit('content-is-pristine', true)
    },
    async getDocumentTypes () {
      return (await this.$http().get('/core/v6/cameleon/view-types')).data.types
    },
    duplicateColumn () {
      this.duplicationInprogress = true
      this.id = null
      this.cache.doc.id = null
      this.document.isSystem = false
      this.document.name = undefined
      this.isConditionsValide = false
    },
    async save () {
      if (!this.id) {
        const response = await this.$http().post('/core/v6/cameleon/columns', this.document)
        if (!this.isSidePanel) {
          this.$nextTick(() => {
            this.$router.push('/columns/' + response.data?.id)
          })
        } else {
          this.id = response.data?.id
          this.$nextTick(() => {
            this.refreshDocument()
            this.$emit('replace-document', { id: response.data?.id, type: 'columns' })
          })
        }
        return response
      } else {
        return await this.$http().put(`/core/v6/cameleon/columns/${this.id}`, this.document).then(_ => GlobalSearch.activeSearch.execute())
      }
    },
    async deleteFilterCheck () {
      await this.$http().delete(`/core/v6/cameleon/columns/${this.id}/check`)
      await this.deleteFilter()
    },
    async deleteFilter () {
      await this.$http().delete(`/core/v6/cameleon/columns/${this.id}`)
      this.close()
    },
    close () {
      if (!this.isSidePanel) {
        this.$router.push('/').catch(e => console.log(e))
      } else {
        this.$nextTick(p => {
          this.$emit('close-document')
        })
      }
    },
    openInSidePanel () {
      const params = {
        columnType: this.columnType,
        targetDocTy: this.computedTargetDocTy
      }
      sidePanelController.displayDocument(this.id, 'columns', params)
    },
    cancel () {
      this.refreshDocument()
    },
    setCanSave (v) {
      this.canSaveComponent = v
    },
    validateForm () {
      this.$nextTick(() => {
        this.$refs?.form?.validate?.()
      })
    },
    async checkColumn () {
      if (!this.document.field) {
        this.setIsConditionValide(false)
        return
      }

      if (!this.showSave) {
        this.setIsConditionValide(true)
        return
      }

      if (this.$refs.form?.validate()) {
        const request =
          !this.id
            ? this.http.post('/core/v6/cameleon/columns/check', this.document)
            : this.http.put(`/core/v6/cameleon/columns/${this.id}/check`, this.document)

        await request
          .then(() => this.setIsConditionValide(true))
          .catch(e => this.setIsConditionValide(false, e))
      }
    },
    setExpressionValide (v) {
      this.dataIsExpressionValide = v
      this.setCanSave(this.isExpressionValide && this.isConditionsValide)
    },
    setIsConditionValide (v, e) {
      const conflictCultureNames = e?.response?.data?.conflictCultureNames
      this.duplicateColumnId = e?.response?.data?.duplicateColumnId
      this.nameErrors = conflictCultureNames ? [{ message: this.$t('t.ConflictCultureNames'), cultures: conflictCultureNames }] : []
      this.isConditionsValide = v
      this.setCanSave(this.isExpressionValide && this.isConditionsValide)
    }
  },
  props: {
    documentId: String,
    columnType: {
      type: String,
      default: 'type-column'
    },
    params: Object,
    isSidePanel: Boolean,
    scope: [String, Object]
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        if (id) {
          this.getAudit()
        }
      }
    },
    'auditController.dataAudit': {
      immediate: true,
      handler (n, o) {
        if (n || o) {
          this.getAudit()
        }
      }
    },
    '$route.params.id': {
      immediate: true,
      handler (id) {
        if (!this.isSidePanel) {
          if (!id && this.duplicationInprogress) {
            this.duplicationInprogress = false
          } else {
            if (!this.documentId) {
              this.id = id
              this.refreshDocument()
            }
          }
        }
      }
    },
    isConditionsValide: {
      immediate: true,
      handler (n, o) {
        this.doAnimation = n !== o
        setTimeout(() => {
          this.doAnimation = false
        }, 5000)
      }
    },
    documentId: {
      immediate: true,
      handler (v) {
        if (v && v !== this.id) {
          this.id = v
        }
        this.refreshDocument()
      }
    },
    'document.params': {
      deep: true,
      handler () {
        this.checkColumnEmit()
      }
    },
    'params.targetDocTy': {
      immediate: true,
      handler (targetDocTy) {
        this.computedTargetDocTy = this.document?.viewType ?? targetDocTy ?? 'accounts'
        this.setFieldToCondition()
      }
    },
    'tab.isPristine': {
      handler (v) {
        if (this.isSidePanel) {
          this.$emit('content-is-pristine', v)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%

.condition-validation-text
  font-size 12px
  opacity 0.6
</style>
